<template>
  <div class="row child-component">
    <div
      class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto">
      <!-- <div class="d-block row col-lg-8 offset-lg-2">
            <OmniSelect :showSearch="false" :showSelectAll="false" selectAllText="All" unSelectAllText="None" multiple="true" v-model="MediaVendor.MediaTypeCategory" :options="MediaTypeCategory" name="Media Category" placeholder="Select Media Category" />
        </div> -->

      <div class="d-block row col-lg-8 mx-auto">
        <b-form-group>
          <slot name="label">
            <span name="vendor-digital-media">Is this Request Digital?</span>
            <custom-tooltip
              :content="
                'Digital media type Search, Interactive, Social, Video, Bundled, Unbundled, Digital Audio will be added to list if yes'
              "
            ></custom-tooltip>
          </slot>
          <b-form-radio-group
            id="digital-media"
            v-model="MediaVendor.IsDigitalMedia"
            name="digital-media"
          >
            <b-form-radio value="true">Yes</b-form-radio>
            <b-form-radio value="false">No</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </div>
      <div
        class="d-block row col-lg-8 mx-auto"
        v-if="MediaVendor.IsDigitalMedia == 'true'"
      >
        <label style="line-height:1"
          ><b> Digital Media types:</b> Search, Interactive, Social, Video,
          Bundled, Unbundled, Digital Audio
        </label>
      </div>
      <div class="d-block row col-lg-8 mx-auto" style="margin-top:35px">
        <OmniSelect
          v-model="MediaVendor.MediaType"
          :options="MediaTypesWithoutDigital"
          name="Select any other media type if required"
          placeholder="Select Media Type"
        />
        <span
          v-if="MediaVendor.IsDigitalMedia == 'true'"
          :class="MediaVendor.MediaType ? 'btn-clear' : 'd-none'"
          @click="clearMediaSelection"
          >Clear Media Type</span
        >
      </div>
      <div
        class="d-block row col-lg-8 mx-auto my-3"
        v-if="
          MediaVendor.MediaType === 'TV' || MediaVendor.MediaType === 'Radio'
        "
      >
        <b-form-input
          @blur="SetDirty('CallLetters')"
          @focus="ResetDirty('CallLetters')"
          autocomplete="off"
          id="CallLetters"
          class="omniipclass"
          v-model="MediaVendor.CallLetters"
          placeholder="Enter Call Letters"
        ></b-form-input>
        <div
          class="error-message-format"
          v-if="
            $v.MediaVendor.CallLetters.$dirty &&
              !$v.MediaVendor.CallLetters.required
          "
        >
          Required field
        </div>

        <b-form-input
          @blur="SetDirty('MarketNumbers')"
          @focus="ResetDirty('MarketNumbers')"
          autocomplete="off"
          id="MarketNumbers"
          class="omniipclass mt-3"
          v-model="MediaVendor.MarketNumbers"
          placeholder="Enter Market Numbers"
        ></b-form-input>
        <div
          class="error-message-format"
          v-if="
            $v.MediaVendor.MarketNumbers.$dirty &&
              !$v.MediaVendor.MarketNumbers.required
          "
        >
          Required field
        </div>

        <b-form-input
          @blur="SetDirty('MarketName')"
          @focus="ResetDirty('markMarketNameetName')"
          autocomplete="off"
          id="MarketName"
          class="omniipclass mt-3"
          v-model="MediaVendor.MarketName"
          placeholder="Enter Market Name"
        ></b-form-input>
        <div
          class="error-message-format"
          v-if="
            $v.MediaVendor.MarketName.$dirty &&
              !$v.MediaVendor.MarketName.required
          "
        >
          Required field
        </div>
      </div>
      <!-- <div class="d-block row col-lg-8 offset-lg-2">
            <OmniCustomSelect  :groupSelect="true" :showSearch="false" :showSelectAll="false" selectAllText="All" unSelectAllText="None" multiple="true" v-model="MediaVendor.MediaTypes" :options="MediaTypes" name="Media Types" placeholder="Select Media Types" />
        </div> -->
      <div class="d-block row col-lg-8 mx-auto">
        <b-form-group label="Is this Request for a Media Rep?">
          <b-form-radio-group
            id="media-rep"
            v-model="MediaVendor.IsMediaRepRequest"
            name="media-rep"
          >
            <b-form-radio value="true">Yes</b-form-radio>
            <b-form-radio value="false">No</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </div>
      <p>
    </p>
      <div class="d-block row col-lg-8 mx-auto" v-if="IsMediaRepRequest">
        <label>Please provide sample invoice or invoice copy</label>
        <vue-dropzone v-on:vdropzone-mounted="mountedmyVueDropzone"
          :duplicateCheck="true"
          ref="myVueDropzone"
          id="dropzone"
          :useCustomSlot="true"
          class="customdropzone"
          :include-styling="false"
          :options="dropzoneOptions"
          v-on:vdropzone-file-added="UploadedToDropzone"
          v-on:vdropzone-removed-file="RemovedFromDropzone"
        >
          <div class="dropzone-custom-content">
            <p class="Drag-and-drop-file-h">
              <b-img
                :src="closeFilterIcon"
                style="margin-top: -7px !important; font-family: 'Open Sans'"
              />
              Drag and drop file here or
              <span class="draganddropspan"> browse </span>
            </p>
          </div>
        </vue-dropzone>
      </div>
    </div>
    <div
      class="form-group pt-60 text-right  footer-buttons"
      style="padding-right:96px !important;"
    >
      <button class="btn btn-link text-uppercase" @click="Cancel()">
        CANCEL
      </button>
      <!-- <button v-if="IsCreditCardNotAccepted" class="text-uppercase btn custom-button btn-primary" disabled>
            NEXT
        </button> -->
      <button
        class="text-uppercase btn custom-button btn-primary"
        @click="Next()"
        :disabled="this.$v.$invalid"
      >
        NEXT
      </button>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapGetters } from "vuex";
import { required, minLength, requiredIf } from "vuelidate/lib/validators";
import {
  NETWithoutDigital,
  NETDigital,
  SPOTWithoutDigital,
  SPOTDigital,
  PRINTWithoutDigital,
  PRINTDigital,
} from "../../../utilities/MediaList";

// import OmniCustomSelect from "../../Common/OmniCustomSelect";
export default {
  components: {
    vueDropzone: vue2Dropzone,
    // OmniCustomSelect
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    isDraft: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      newFile: [],
      reachUserData:null,
      FilesUploaded: false,
      // MediaTypes:[],
      NetMediaOptions: [
        {
          label: "Cable FEP Streaming",
          value: "Cable FEP Streaming",
        },
        {
          label: "Cable VOD",
          value: "Cable VOD",
        },
        {
          label: "Network Streaming",
          value: "Network Streaming",
        },
      ],
      // MediaVendor: {
      //     IsMediaRepRequest: null,
      //     MediaTypes: [],
      //     IsDigitalMedia:null

      // },
      dropzoneOptions: {
        paramName: "file",
        //params: {'param_1':'xyz','para_2': localid },
        previewTemplate: this.template(),
        url: `${process.env.VUE_APP_API_ROOT}api/v1/mdm/Upload`,
        uploadMultiple: true,
        maxFiles: 5,
        minFiles: 1,
        maxFilesize: 10,
        addRemoveLinks: false,
        parallelUploads: 5,
        createImageThumbnails: false,
        autoProcessQueue: true,
        //  headers:{"Authorization":'Bearer eyJraWQiOiJRS2V5YUxEY2RuQm9YKzlibm4rTGJQbjQrMVhiMjFuR2hHQ0l2aHpPQmJrPSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoicldwdUNRbV9PenBvZjhwUG1iOFg4QSIsInN1YiI6IjgzNWU1NGQxLWY4YzMtNDJiYy1hZGQ2LTI4Y2RmZjdiNTc4ZiIsImNvZ25pdG86Z3JvdXBzIjpbImV1LXdlc3QtMV9yemluaUtwNzJfT01HLUFERlMiXSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LXdlc3QtMS5hbWF6b25hd3MuY29tXC9ldS13ZXN0LTFfcnppbmlLcDcyIiwiY29nbml0bzp1c2VybmFtZSI6Ik9NRy1BREZTX2NoYW5kcnUucG9ubnVzYW15QGFubmFsZWN0LmNvbSIsImdpdmVuX25hbWUiOiJDaGFuZHJ1IiwiYXVkIjoiNzM3MGlzOXZnb2Ewb2g2OXR2MHY2dDAxdXUiLCJpZGVudGl0aWVzIjpbeyJ1c2VySWQiOiJjaGFuZHJ1LnBvbm51c2FteUBhbm5hbGVjdC5jb20iLCJwcm92aWRlck5hbWUiOiJPTUctQURGUyIsInByb3ZpZGVyVHlwZSI6IlNBTUwiLCJpc3N1ZXIiOiJodHRwOlwvXC9vbWdzc28ub25lb21nLmNvbVwvYWRmc1wvc2VydmljZXNcL3RydXN0IiwicHJpbWFyeSI6InRydWUiLCJkYXRlQ3JlYXRlZCI6IjE2MDE5OTU4MzIxMTEifV0sInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjA3MzM4MjM0LCJleHAiOjE2MDkzMDg5MzUsImlhdCI6MTYwOTMwNTMzNSwiZmFtaWx5X25hbWUiOiJQb25udXNhbXkifQ.A66H0Vuv-pxNRKKOvG6BXsu4bK-Q0NfI5ORK8A8F8Vc59-RwHnuGc4tOL9TZT215YGA8kAh3o4WVs0YMFwXX5hmt7jKOCKKQyCjDhoLMPKNDVZ5eWULEoXVqHky5ZewvVc5y3Evo4rLsfHnyT1OC4j24oc8lwFFOshnqGGzkVNQ8zE4z39MdlioC9zQVxSzaxbAbnyol5qHQWQMXFhLHZOlrQ_5qxYirGdeZd0W7gdY6W8Asqj6thQ37tLf9537B6H54UKPxVeQYzxazbgsWqOFYs8jcMVTxI8dEIK25V-LgPf5Lba3BhqqaVGU_kVcPHH0qzmtRr38tfMIsZi-2Bg'},
        // headers: { "My-Awesome-Header": "header value", GUID: localStorage.getItem("UploadfileId") },
        init: function() {
          this.on("addedfile", function(file) {
            if (this.files.length > 5) {
              this.removeFile(file);
              window.alert("Maximum File Upload limit is 5!");
            }
          });

          this.on("removedfile", function() {});
        },
      },
        uploadedFiles:[] ,
        apiFiles:[] ,
    };
  },
  validations: {
    MediaVendor: {
      IsDigitalMedia: {
        required: required,
      },
      MediaType: {
        required: requiredIf(function() {
          return this.MediaVendor.IsDigitalMedia == false || this.MediaVendor.IsDigitalMedia == 'false';
        }),
      },
      MarketName: {
        required: requiredIf(function() {
          return this.subMediaData && this.subMediaMarketName;
        }),
      },
      CallLetters: {
        required: requiredIf(function() {
          return this.subMediaData && this.subMediaCallLetters;
        }),
      },
      MarketNumbers: {
        required: requiredIf(function() {
          return this.subMediaData && this.subMediaMarketNumbers;
        }),
      },
      IsMediaRepRequest: {
        required: required,
      },
      VendorInvoiceCopyFiles: {
        minLength: minLength(1),
        required: requiredIf(function(model) {
          return (
            model.IsMediaRepRequest == true || model.IsMediaRepRequest == "true"
          );
        }),
      },
    },
  },
  activated() {
    this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
  },
  watch: {
    "MediaVendor.ID": function(val) {
      this.id = parseFloat(val);
      if (val > 0) {
        this.ManuallyUploadFiles(this.MediaVendor.VendorInvoiceCopyFiles);
        this.$set(this.MediaVendor, "VendorInvoiceCopyFiles", this.GetFilesObject());
      }
    },
    "MediaVendor.IsMediaRepRequest": function(val) {
      if (val == false || val == "false") {
        this.$set(this.MediaVendor, "VendorInvoiceCopyFiles", []);
      }
    },
    "MediaVendor.MediaType": function(val) {
      PRINTWithoutDigital.find(el => {
        if(el.MediaType == val) {
          this.MediaVendor.PrintValueSelected = el.MediaType;
        }
      });
    },
  },
  async mounted() {
     await this.getReachData();
        if (!this.CanCreatProductionExpenseNewMediaRequest) {
            this.$router.push({
                name: "error",
                params: { message: "UnAuthorized" },
            });
        }
    },
  computed: {
    CanCreatProductionExpenseNewMediaRequest() {
      return this.reachUserData && this.reachUserData.MatrixEmailID && this.reachUserData.MatrixEmailID.trim() != "";
    },
    subMediaData() {
      return (
        this.MediaVendor.MediaType === "TV" ||
        this.MediaVendor.MediaType === "Radio"
      );
    },
    subMediaCallLetters() {
      return !this.MediaVendor.MarketNumbers && !this.MediaVendor.MarketName;
    },
    subMediaMarketNumbers() {
      return !this.MediaVendor.CallLetters && !this.MediaVendor.MarketName;
    },
    subMediaMarketName() {
      return !this.MediaVendor.CallLetters && !this.MediaVendor.MarketNumbers;
    },
    IsEdit() {
      return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
    },
    ...mapGetters("mediavendor", {
      MediaVendor: "MediaVendor",
    }),
    MediaTypeCategory() {
      return [
        {
          label: "Normal",
          value: "1",
        },
        {
          label: "Digital",
          value: "2",
        },
      ];
    },
    MediaTypes() {
      let list = [];
      list.push({
        label: "Net",
        value: NETDigital,
      });
      list.push({
        label: "Spot",
        value: SPOTDigital,
      });
      list.push({
        label: "Print",
        value: PRINTDigital,
      });
      return list;
    },
    MediaTypesWithoutDigital() {
      let list = [];
      list.push({
        label: "Net",
        value: NETWithoutDigital,
      });
      list.push({
        label: "Spot",
        value: SPOTWithoutDigital,
      });
      list.push({
        label: "Print",
        value: PRINTWithoutDigital,
      });
      return list;
    },
    IsMediaRepRequest() {
      return (
        this.MediaVendor.IsMediaRepRequest == true ||
        this.MediaVendor.IsMediaRepRequest == "true"
      );
    },
  },
  methods: {
    async getReachData() {
      try {
        const emailAddress = await this.$store.dispatch("getEmail");
        const res = await this.$store.dispatch("product/GetReachUser", emailAddress);
        this.reachUserData = res.data;
      } catch (ex) {
        if (ex.response) {
          this.$store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
        }
      }
    },
      mountedmyVueDropzone() {
          if (this.MediaVendor.ID > 0 && this.FilesUploaded == false) {
              this.ManuallyUploadFiles(this.MediaVendor.VendorInvoiceCopyFiles);
              this.$set(this.MediaVendor, "VendorInvoiceCopyFiles", this.GetFilesObject());
          }
      },
    SetDirty(property) {
      this.$v.MediaVendor[property].$touch();
    },
    ResetDirty(property) {
      this.$v.MediaVendor[property].$reset();
    },
    clearMediaSelection() {
      this.MediaVendor.MediaType = "";
      this.MediaVendor.CallLetters = "";
      this.MediaVendor.MarketNumbers = "";
      this.MediaVendor.MarketName = "";
    },
    RenderUploadedFiles(files) {
            if (this.$refs.myVueDropzone != null) {
                if (files != null && files.length > 0 && this.FilesUploaded == false) {
                    files.forEach((fileUploaded) => {
                        let file = {
                            size: fileUploaded.Length,
                            name: fileUploaded.Name,
                            type: fileUploaded.Extension,
                        };
                        var url = fileUploaded.FullName;
                        this.$refs.myVueDropzone.manuallyAddFile(file, url);
                    });
                }
                this.FilesUploaded = true;
            }
    },
    // SetErrorMessage(message, ex) {
    //     this.$store.dispatch(
    //         "progress/setErrorMessage",
    //         `${message}. The error is ${ex}`
    //     );
    // },
    // mountedmyVueDropzone() {
    //     if (this.MediaVendor.ID > 0 && this.FilesUploaded == false) {
    //         this.ManuallyUploadFiles(this.MediaVendor.VendorInvoiceCopyFiles);
    //     }
    // },
    ManuallyUploadFiles(files) {
      if (this.$refs.myVueDropzone != null) {
        if (files != null && files.length > 0 && this.FilesUploaded == false) {
          files.forEach((fileUploaded) => {
            let file = {
              size: fileUploaded.Length,
              name: fileUploaded.Name,
              type: fileUploaded.Extension,
            };
            var url = fileUploaded.FullName;
            this.$refs.myVueDropzone.manuallyAddFile(file, url);
          });
        }
        this.FilesUploaded = true;
      }
    },
    UploadedToDropzone() {
      this.$set(
        this.MediaVendor,
        "VendorInvoiceCopyFiles",
        this.GetFilesObject()
      );
    },
    RemovedFromDropzone() {
      this.$set(
        this.MediaVendor,
        "VendorInvoiceCopyFiles",
        this.GetFilesObject()
      );
    },
        GetFilesObject() {
            let files = [];
            const dropzone = this.$refs.myVueDropzone;
            dropzone.processQueue();
            if (this.$refs.myVueDropzone.dropzone.files && this.$refs.myVueDropzone.dropzone.files.length > 0) {
                let filesExists = this.$refs.myVueDropzone.dropzone.files;
                for (let i = 0; i < filesExists.length; i++) {
                    files.push({
                        name: filesExists[i].name,
                        file: filesExists[i]
                    });
                }
                return files;
            } else {
                return files;
            }
        },
        fileUploaded(file) {
          this.uploadedFiles.push(file)
        },
    template: function() {
      return `<div class="dz-preview dz-file-preview" style='background-color:#ffffff !important;display:flex;'>

                <div class="dz-details" style='margin-right:79px !important;'>
                    <div class="dz-size" style="display:none;" ><span data-dz-size></span></div>
                    <div class="FileforNewClientxls-Copy" style="margin-left:20px;width:280px;cursor:default !important;" ><span style='word-break:break-all !important;font-size:14px !important;font-weight:400;font-stretch:normal;font-style: normal;line-height: 0.71;color: #00a1d2 !important; font-family:"Open Sans" !important;' data-dz-name></span></div>
                </div>
                <a>
                 <img data-dz-remove=""  style='' src='/icons/fileremove.svg'  @click="DeleteServerFile()" ></img>
                </a>
            </div>`;
    },
    Next() {
        this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
        if (this.id) {
            this.$router.push({
                name: "Media Vendor External Attachment",
                params: {
                    id: this.id,
                    isDraft: this.isDraft
                }
            });
        } else {
            this.$router.push({
                name: "Media Vendor External Attachment"
            });
        }
    },
    Cancel() {
      this.$router.push("/VendorRequests");
    },
  },
};
</script>

<style>
/*file upload starts*/
.btn-clear {
  position: absolute;
  top: auto;
  left: auto;
  right: 15px;
  bottom: 36px;
  font-size: 11px !important;
  font-weight: bold;
  color: #018db8;
  background: transparent;
  border: 1px solid #018db8;
  border-radius: 30px;
  padding: 3px 15px;
  display: flex;
  cursor: pointer;
}

.customdropzone {
  width: 411px !important;
  height: 44px !important;
  border-radius: 8px !important;
  border: dashed 2px #e9eef0 !important;
  background-color: #ffffff !important;
  cursor: pointer !important;
}

.Drag-and-drop-file-h {
  /* margin-left: 97px !important; */
  margin-top: 14px !important;
  width: 237px !important;
  height: 10px !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 0.71 !important;
  letter-spacing: normal !important;
  color: #a2a9ad !important;
}

.draganddropspan {
  color: #00a1d2;
}

section#digital-media-options > ul > li > ul > li {
  pointer-events: none !important;
}

section#digital-media-options > ul > li > ul > li > div {
  opacity: 0.5 !important;
  /* Fade effect */
  cursor: not-allowed;
  /* Cursor change to disabled state*/
}

fieldset.form-group > div.first-child {
  color: rgb(77, 81, 97) !important;
  font-size: 11px !important;
  text-transform: uppercase !important;
}

/*file upload ends*/
</style>
